import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import GetAccessSection from '../components/research/GetAccessSection';
import OpenScienceSection from '../components/research/OpenScienceSection';
import CollaborationSection from '../components/research/CollaborationSection';
import DriveInnovationSection from '../components/research/DriveInnovationSection';
import JoinTeamSection from '../components/providers/JoinTeamSection';
import FooterWrapper from '../components/index/FooterWrapper';

const ResearchPage = () => (
  <Layout>
    <Navigation />
    <GetAccessSection />
    <OpenScienceSection />
    <CollaborationSection />
    <DriveInnovationSection />
    <FooterWrapper children={<JoinTeamSection email={'mailto:science@joinmodernhealth.com'}/>} />
  </Layout>
);

export default ResearchPage;
