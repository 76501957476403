import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import collaborationIcon from '../../images/for_providers-04.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';
import { SectionIcon, ContentWrapper, ContentRow } from '../businesses/styles';
import { SectionWrapper } from './styles';

const CollaborationSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <S.CardHeading css={{ maxWidth: '32rem' }}>Collaboration is the key</S.CardHeading>
          <S.SectionDescription small css={{ maxWidth: '25rem' }}>
            We have a dedicated research team and would love to collaborate to build out the
            evidence for our services for different groups or populations.
          </S.SectionDescription>
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 2 }} xxl={10}>
          <SectionIcon centered src={collaborationIcon} />
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default CollaborationSection;
