import styled from '@emotion/styled';
import { Row } from 'antd';

import { mediaQueries, color } from '../variables';

export const SectionWrapper = styled(Row)`
  padding: 1rem 9rem 1rem 20rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 1rem 9rem 1rem 14rem;
  }
  ${mediaQueries.xxl} {
    padding: 5rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem;
  }
`;
