import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

import { mediaQueries, color } from '../variables';
import scienceIcon from '../../images/for_providers-02.svg';
import * as S from '../shared/components/styles';
import { ContentRow, SectionIcon } from '../businesses/styles';

const SectionWrapper = styled(Row)`
  padding: 7rem 9rem 1rem 20rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 7rem 9rem 1rem 14rem;
  }
  ${mediaQueries.xxl} {
    padding: 7rem 9rem 0rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 0rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem;
  }
`;

const ContentWrapper = styled.div`
  text-align: left;
  ${mediaQueries.lg} {
    justify-content: center;
  }
  ${mediaQueries.md} {
    display: flex;
    flex-direction: column;
    background-color: ${color.white};
    border-radius: 1rem;
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 2rem;
  }
`;

const OpenScienceSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon
            css={{ webkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)' }}
            extraMargin
            centered
            src={scienceIcon}
          />
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 4 }} xxl={{ span: 10, offset: 3 }}>
          <S.CardHeading css={{ maxWidth: '32rem' }}>Open science</S.CardHeading>
          <S.SectionDescription small css={{ maxWidth: '25rem' }}>
            Modern Health is committed to evidence-based care and the translation of basic research
            to improve care for all.
          </S.SectionDescription>
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default OpenScienceSection;
