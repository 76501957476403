import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

import { mediaQueries, color } from '../variables';
import innovationIcon from '../../images/for_providers-06.svg';
import * as S from '../shared/components/styles';
import { ContentRow, SectionIcon } from '../businesses/styles';
import { SectionWrapper } from './styles';

const ContentWrapper = styled.div`
  text-align: left;
  ${mediaQueries.lg} {
    justify-content: center;
  }
  ${mediaQueries.md} {
    display: flex;
    flex-direction: column;
    background-color: ${color.white};
    border-radius: 1rem;
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 2rem;
  }
`;

const DriveInnovationSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon extraMargin centered src={innovationIcon} />
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 4 }} xxl={{ span: 10, offset: 3 }}>
          <S.CardHeading css={{ maxWidth: '32rem' }}>Drive innovation</S.CardHeading>
          <S.SectionDescription small>
            We believe in fostering collaboration and open sharing of knowledge to help drive
            innovations in brain health. If you are interested in collaborating, please reach out to
            us - we’re excited to work with you.
          </S.SectionDescription>
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default DriveInnovationSection;
